/* overrides */
$primary: #003825;

@import '~bootstrap/scss/bootstrap';
@import url('https://fonts.googleapis.com/css?family=Crimson+Text');

a {
  color: $primary;

  &:hover {
    color: $primary;
  }
}

body {
  font-size: 16px;
  font-family: 'Crimson Text', serif;
  color: $primary;
}

main {
  padding: 20px 0px;
  color: $primary;
}

.table {
  color: $primary;
}

footer {
  background-color: #e2e3e5;
}

.no-flex {
  flex: none !important;
}

.navigation {
  ul.pagination {
    flex-wrap: wrap !important;
  }
}

@include media-breakpoint-down(sm) {
  .jumbotron-figures {
    h1 {
      font-size: 1.4rem;
    }
  }
}

@include media-breakpoint-up(md) {
  .card-img-top {
    width: 100%;
    height: 30vw;
    object-fit: cover;
  }
}

@include media-breakpoint-up(lg) {
  .card-img-top {
    width: 100%;
    height: 30vw;
    object-fit: cover;
  }
}

@include media-breakpoint-up(xl) {
  .card-img-top {
    width: 100%;
    height: 10vw;
    object-fit: cover;
  }
}


@media only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (min--moz-device-pixel-ratio: 2),
only screen and (-o-min-device-pixel-ratio: 2/1),
only screen and (min-device-pixel-ratio: 2),
only screen and (min-resolution: 192dpi),
only screen and (min-resolution: 2dppx) {
  @include media-breakpoint-up(md) {
    .card-img-top {
      width: 100%;
      height: 30vw;
      object-fit: cover;
    }
  }

  @include media-breakpoint-up(lg) {
    .card-img-top {
      width: 100%;
      height: 30vw;
      object-fit: cover;
    }
  }

  @include media-breakpoint-up(xl) {
    .card-img-top {
      width: 100%;
      height: 20vw;
      object-fit: cover;
    }
  }
}

label.required:after {
  content: " *";
  color: grey;
}

img.series-figure-image {
  max-height: 50px;
  max-width: 50px;
  border-radius: 50%;
}

.e-mail:before {
  content: attr(data-website) "\0040" attr(data-user);
  unicode-bidi: bidi-override;
  direction: rtl;
}

.jumbotron {
  border-radius: 0;

  &.jumbotron-figures {
    height: 30vh;
    color: white;
    background-image: url('../images/jumbo-figuren.jpg');
    background-size: 1110px;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: top;
    text-shadow: 0px 0px 4px $primary;

    .container:first-child {
      position: relative;
      height: 100%;

    }

    h1.jumbotron-heading {
      position: absolute;
      bottom: 0;
      width: 100%;
      text-align: center;
    }
  }
}

[href^="http"]:not([href*="wukpedia.de"])::after {
  content: '(external link)';
  display: inline-block;
  width: 1em;
  color: $primary;
  height: 1em;
  text-indent: 1em;
  white-space: nowrap;
  overflow: hidden;
  background-image: url('../images/external-link.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  margin-left: .1em;
}

.sortable {
  color: $text-muted;
}

.asc:after {
  content: '\2193';
  margin-left: 3px;
}

.desc:after {
  content: '\2191';
  margin-left: 3px;
}